<template>
    <div class="reset-password-page">
        <div class="reset-password-section">
            <div class="wrapper">
                <div class="reset-password-wrapper">
                    <div class="reset-password-form-wrapper">
                        <h2 class="title">{{ $t(`Reset Your Password`) }}</h2>
                        <form @submit.prevent="resetPassword" class="reset-password-form">
                            <div class="input-container">
                                <label for="email" class="desc">{{ $t(`Email`) }}</label>
                                <input type="email" id="email" v-model="email" required>
                            </div>
                            <div class="input-container">
                                <label for="password" class="desc">{{ $t(`New Password`) }}</label>
                                <input type="password" id="password" v-model="password" required>
                            </div>
                            <div class="input-container">
                                <label for="password_confirmation" class="desc">{{ $t(`Confirm New Password`) }}</label>
                                <input type="password" id="password_confirmation" v-model="password_confirmation"
                                    required>
                            </div>
                            <!-- <div class="pass-rules">
                                <p class="desc bold">{{ $t(`Password must contain:`) }}</p>
                                <p class="desc">{{ $t(`- At least 8 characters`) }}</p>
                                <p class="desc">{{ $t(`- At least one uppercase letter`) }}</p>
                                <p class="desc">{{ $t(`- At least one lowercase letter`) }}</p>
                                <p class="desc">{{ $t(`- At least one number`) }}</p>
                            </div> -->
                            <button type="submit" class="button" :disabled="isLoading">
                                {{ isLoading ? 'Resetting...' : 'Reset Password' }}
                            </button>
                            <p v-if="message" class="message" :class="{ 'error': status === 'ERROR' }">{{ message }}</p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResetPassword',
    props: {
        isAuth: Boolean
    },
    created() {
        this.redirectIfAuth();
    },
    data() {
        return {
            email: '',
            password: '',
            password_confirmation: '',
            message: '',
            status: '',
            isLoading: false,
            errorTimer: null
        }
    },
    mounted() {
        if (this.$route.query.email) {
            this.email = this.$route.query.email
        }
    },
    watch: {
        isAuth() {
            this.redirectIfAuth();
        }
    },
    methods: {
        redirectIfAuth() {
            if (this.$parent.isAuth) {
                this.$router.push({ name: 'Home' });
            }
        },
        async resetPassword() {
            this.clearError();

            if (this.isLoading) return;

            this.isLoading = true;

            this.$http.post(process.env.VUE_APP_API + 'reset-password', {
                token: this.$route.query.token ?? '',
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation
            })
                .then((response) => {
                    if (response.data.status === 'OK') {
                        this.$toast.success(response.data.message);
                        this.$router.push({ name: 'Home' });
                    } else {
                        this.showError(response.data.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.showError(error.response?.data?.message || 'An error occurred');
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        showError(errorMessage) {
            this.status = 'ERROR';
            this.message = errorMessage;

            // Clear any existing timer
            if (this.errorTimer) {
                clearTimeout(this.errorTimer);
            }

            // Set a new timer to clear the error after 2 seconds
            this.errorTimer = setTimeout(() => {
                this.clearError();
            }, 6000);
        },
        clearError() {
            this.status = '';
            this.message = '';
            if (this.errorTimer) {
                clearTimeout(this.errorTimer);
                this.errorTimer = null;
            }
        }
    },
    beforeUnmount() {
        // Clear the timer if the component is destroyed
        if (this.errorTimer) {
            clearTimeout(this.errorTimer);
        }
    }
}
</script>

<style scoped lang="scss">
.reset-password-page {
    .reset-password-section {
        padding-top: 0;
        text-align: center;

        .wrapper {
            padding: 80px 0;
        }
    }

    .reset-password-wrapper {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        border-radius: 6px;
        overflow: hidden;
        padding: 40px;
        background: #3C3A44;
    }

    .reset-password-form-wrapper {
        background: transparent;
        text-align: center;
        max-width: 100%;
        margin: 0 auto;

        .title {
            margin-bottom: 28px;
            padding: 0;
            font-size: 24px;
            line-height: 32px;
            color: #fff;
        }
    }

    .reset-password-form {
        .input-container {
            width: 100%;
            margin-bottom: 16px;
            text-align: left;

            .desc {
                padding-bottom: 8px;
                font-size: 14px;
                line-height: 19px;
                color: #AAB0C5;
                display: block;
            }

            input {}
        }

        .pass-rules {
            margin-top: 16px;

            .desc {
                color: #AAB0C5;
                font-size: 14px;
                line-height: 19px;
                margin-top: 4px;
            }

            .bold {
                color: #fff;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 8px;
            }
        }

        .message {
            margin-top: 16px;
            font-size: 14px;
            line-height: 19px;
            color: #50AF55;

            &.error {
                color: #F3453E;
            }
        }
    }
}
</style>