<template>
	<div class="menu">
		<ul class="nav">
			<template v-for="(item, i) in $parent.nav">
              <li class="nav__item" :key="i">
                <router-link :to="item.link">{{item.title}}</router-link>
              </li>
            </template>
			<!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
			<a @click="$emit('openSaleSkinModal')">{{ $t(`Sale your skin`) }}</a>
			</div> -->
		</ul>
	</div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/
export default {
	name: 'Menu',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
