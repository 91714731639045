<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="title">{{ $t(`Cart`) }}</div>

          <div class="desc empty-desc" v-if="!$parent.cartContents || ($parent.cartContents && !$parent.cartContents.length)">{{ $t(`Cart is empty!`) }}</div>

          <div class="left" v-if="$parent.cartContents && $parent.cartContents.length">
            <div class="product-list">
              <div class="item" v-for="(item, i) in $parent.cartContents" :key="i">
                <div class="preview">
                  <img :src="getImageUrl(item)" class="img" />
                </div>
                <div class="title">{{ item.item.title || item.item.name }}</div>
                <div>
                  <div class="count">
                    <div class="count__item minus" @click="removeOneFromCart(item, item.item_id, item.item.item_type)">
                    </div>
                    <input type="number" value="1" v-model="item.count" />
                    <div class="count__item plus" @click="addToCart(item, item.item_id, item.item.item_type)"></div>
                  </div>
                  <div class="price">{{ item.item.price }} <span class="currency">{{ $parent.currency }}</span></div>
                </div>
                <div class="delete" @click="removeFromCart(item, item.item_id, item.item.item_type)">
                  <div class="desc">{{ $t(`Delete item`) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right" v-if="$parent.cartContents && $parent.cartContents.length">
            <div class="wrapper">
              <div class="title small">{{ $t(`Contact information`) }}</div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>First name:</div>
                <input type="text" :placeholder="$t('First name')" v-model="name" />
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>Last name:</div>
                <input type="text" :placeholder="$t('Last name')" v-model="surname" />
              </div>

              <div class="input-container">
                <div class="desc"><span class="red">*</span>Email:</div>
                <input type="email" :placeholder="$t('Email')" v-model="email" />
              </div>

              <div class="input-container">
                <div class="desc"><span class="red">*</span>Phone:</div>
                <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                  :default-country-code="convertedShortName" @update="phoneDataUpdate($event)" />
              </div>
              <div class="input-container steam-input-container">
                <div class="desc"><span class="red">*</span>Steam ID:</div>
                <input type="text" :placeholder="$t('Steam ID')" v-model="steamId" />
              </div>

              <div class="input-container country-select-container">
                <div class="desc"><span class="red">*</span>Country:</div>
                <select v-model="country" :class="{ 'empty': !country }">
                  <option value="" disabled selected>{{ $t(`Country`) }}</option>
                  <option v-for="option in countryOptions" :key="option.id" :value="option.id">
                    {{ option.title }}
                  </option>
                </select>
              </div>

              <div class="input-container">
                <div class="desc"><span class="red">*</span>City:</div>
                <input type="text" :placeholder="$t('City')" v-model="city" />
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>Address:</div>
                <input type="text" :placeholder="$t('Address')" v-model="address" />
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>Post Code:</div>
                <input type="text" :placeholder="$t('Post Code')" v-model="zip" />
              </div>
              <div class="input-container">
                <div class="checkbox-container">
                  <label class="checkbox-label">
                    <div class="checkbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms" />
                      <div class="checkbox cart-checkbox"></div>
                      <div class="">
                        <span class="title">{{ $t(`I agree with`) }} </span>
                        <a @click="$parent.goToPage('privacy')" class="title">{{ $t(`privacy policy`) }}</a>
                        <span class="title"> {{ $t(`and`) }} </span>
                        <a @click="$parent.goToPage('terms')" class="title">{{ $t(`terms and conditions`) }}</a>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="wrapper">
              <div class="title small">{{ $t(`Payment Method`) }}</div>
              <div class="row">
                <div class="desc">{{ $t(`Total to pay:`) }}</div>
                <div class="title">{{ $parent.totalSum }} <span class="currency">{{ $parent.currency }}</span></div>
              </div>
              <button @click="submitOrder" :class="['button', { 'disabled': !requiredFieldsAreFilled }]">
                <span>{{ $t(`Confirm`) }}</span>
              </button>
            </div>
          </div>

        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'Cart',
  props: [],
  components: {
    VuePhoneNumberInput
  },
  data: function () {
    return {
      imgDomain: '',
      countryOptionsId: [],
      name: '',
      surname: '',
      phone: '',
      steamId: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      zip: '',
      terms: false,
      phoneFull: "",
      convertedShortName: '',
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',
      email: '',
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProfile();
    this.fetchCountries();
    this.$parent.getCartContents();
  },
  computed: {
    ...mapGetters('country', ['countryOptions', 'countryOptionsIso']),
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.name && this.surname && this.phone &&
        this.steamId && this.country &&
        this.city && this.address
        && this.zip && this.terms
        && this.email
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    ...mapActions('country', ['getCountries']),
    getImageUrl(item) {
      if (item.item.item_type == 'pack') {
        return item.item.img_url;
      }
      return this.imgDomain + item.item.img_url;
    },

    addToCart(item, id, item_type) {
      this.$parent.addToCart(item, id, item_type);
    },

    removeOneFromCart(item, id, item_type) {
      this.$parent.removeOneFromCart(item, id, item_type);
    },

    removeFromCart(item, id, item_type) {
      this.$parent.removeFromCart(item, id, item_type);
    },
    async fetchCountries() {
      try {
        await this.getCountries();
      } catch (error) {
        if (error.status === 401) {
          this.$parent.openSignInModal();
        } else {
          console.error(error);
        }
      }
    },
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    submitOrder() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phoneFull,
        "steamId": this.steamId,
        "country": this.country,
        "city": this.city,
        "address": this.address,
        "postCode": this.zip,
        "email": this.email
      }
      this.$emit('openOrderModal', data);
    },
    getProfile() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
          if (res.status == 200) {
            self.email = res.data.email;
            self.name = res.data.name;
            self.surname = res.data.surname;
            self.phone = res.data.phone.replace(/^[^\s]*\s/, '');
            self.profilePhone = res.data.phone;
            self.address = res.data.address;
            self.city = res.data.city;
            self.zip = res.data.zip;
            self.country = res.data.country;
            self.steamId = res.data.steamId;
            self.getAllCountryCodes();
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    getAllCountryCodes() {
      this.$http.get(process.env.VUE_APP_API + 'phone-codes')
        .then((res) => {
          if (res.data.status == "OK") {
            this.countryCodeList = res.data.payload;
            this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace('+', '');
            this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    getShortNameByPhoneCode(phoneCode, codeList) {
      for (let i = 0; i < codeList.length; i++) {
        const countryCode = Object.keys(codeList[i])[0];
        const codeValue = codeList[i][countryCode].code
        if (codeValue == phoneCode) {
          this.convertedShortName = countryCode;
        }
      }
    },
    getSymbolsBeforeSpace(inputString) {
      const regex = /^[^\s]+/;
      const match = inputString.match(regex);

      if (match) {
        return match[0];
      } else {
        return ""; // Return an empty string if no symbols are found
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
  }
}
</script>

<style scoped lang="scss">
.checkbox-label-wrapper {
  align-items: flex-start;
}
</style>