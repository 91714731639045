<template>
  <div class="product-page">
    <div class="product-details-container" v-if="displayItem">
      <div class="product-image-container">
        <transition name="fade">
          <span
            class="added"
            v-if="$parent.addToCartChosenItem == displayItem.id"
            >{{ $t(`Added`) }}</span
          >
        </transition>
        <img :src="getImageUrl(displayItem)" class="product-image" />
      </div>
      <div class="product-info-container">
        <p v-if="displayItem.type && itemType === 'item'" class="product-type">
          {{ displayItem.type }}
        </p>
        <p
          v-else-if="displayItem.type && itemType === 'pack'"
          class="product-type"
        >
          {{ $t(`Pack`) }}
        </p>

        <h1 class="product-title">
          {{ displayItem.title || displayItem.name }}
        </h1>

        <div class="product-details">
          <p v-if="displayItem.quality" class="product-quality">
            Rarity:
            <span :style="{ color: getColor(displayItem.quality) }">{{
              displayItem.quality
            }}</span>
          </p>

          <p v-if="displayItem.hero" class="product-hero">
            Hero: <span>{{ displayItem.hero }}</span>
          </p>
        </div>

        <div
          class="price-container"
          :class="{
            'has-discount':
              displayItem.old_price && displayItem.old_price !== '0.00',
          }"
        >
          <template
            v-if="displayItem.old_price && displayItem.old_price !== '0.00'"
          >
            <div class="old-price">
              {{ displayItem.old_price }} {{ currency }}
            </div>
            <div class="price desc discounted">
              <span
                ><b>{{ displayItem.price }} {{ currency }}</b></span
              >
            </div>
          </template>
          <template v-else>
            <div class="price desc blue">
              <span
                ><b>{{ displayItem.price }} {{ currency }}</b></span
              >
            </div>
          </template>
        </div>

        <button
          class="add-to-cart-button button"
          @click="addToCart(displayItem, displayItem.id, displayItem.item_type)"
        >
          <img src="./../assets/cart.svg" class="img" />
          <span>{{ $t(`Add to Cart`) }}</span>
        </button>

        <div
          v-if="itemType === 'item' && chartData.length > 3"
          class="chart-section"
        >
          <h3 class="chart-title">{{ $t(`Sale price`) }}</h3>
          <div class="chart-container">
            <canvas ref="chartCanvas"></canvas>
          </div>
        </div>

        <div
          v-if="
            displayItem &&
            itemType == 'item' &&
            displayItem.last_sale &&
            displayItem.last_sale.date &&
            displayItem.last_sale.price
          "
          class="last-sale"
        >
          <div class="desc">
            Last sale: {{ displayItem.last_sale.date | moment("MMM DD") }} for
            {{ displayItem.last_sale.price }} {{ currencySymbol }}
          </div>
        </div>

        <div
          v-if="itemType === 'pack'"
          class="content_desc"
          v-html="displayItem.content_desc"
        ></div>
        <div v-if="itemType === 'pack'" class="disclaimer">
          <h2>{{ $t(`Disclaimer`) }}</h2>
          <p v-if="$te('random_case_distribution')">
            {{ $t("random_case_distribution") }}
          </p>
          <p v-else>
            Please be advised that items obtained from these cases are
            distributed on a purely random basis. The value of the items you
            receive can vary significantly, generally ranging from equal to the
            cost of the case itself to potentially slightly exceeding the case's
            value. It's important to understand that due to the random nature of
            item distribution, we cannot guarantee the exact value or specific
            items you will receive from any given case. By purchasing a case,
            you acknowledge and accept these terms and conditions of the
            platform. If you have any questions or concerns, please contact our
            customer support before making a purchase.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import { mapGetters } from "vuex";
import "chartjs-adapter-moment";

export default {
  name: "ProductPage",
  props: ["addToCartChosenItem", "currency", "id", "slug"],
  data() {
    return {
      packData: null,
      itemType: "item",
      colorMap: {
        common: "#b0c3d9",
        uncommon: "#5e98d9",
        rare: "#4b69ff",
        mythical: "#8847ff",
        immortal: "#e4ae39",
        legendary: "#d32ce6",
        seasonal: "#fff34f",
        arcana: "#ade55c",
        ancient: "#eb4b4b",
      },
      chart: null,
    };
  },
  computed: {
    ...mapGetters("app", ["currencySymbol"]),
    displayItem() {
      return this.packData || this.$parent.productPageItem;
    },
    chartData() {
      if (this.displayItem && this.displayItem.price_history) {
        return this.displayItem.price_history;
      }
      return [];
    },
  },
  watch: {
    currency() {
      if (this.id) {
        this.$parent.getProductPageContent(this.id);
        this.itemType = "item";
      } else if (this.slug) {
        this.itemType = "pack";
        this.fetchPackData();
      }
    },
    chartData: {
      handler(newData) {
        if (newData.length > 0) {
          this.$nextTick(this.createChart);
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.id) {
      this.$parent.getProductPageContent(this.id);
      this.itemType = "item";
    } else if (this.slug) {
      this.itemType = "pack";
      this.fetchPackData();
    }
  },
  methods: {
    fetchPackData() {
      this.$http
        .get(
          `${process.env.VUE_APP_API}packs/${this.slug}?currency=${this.currency}`
        )
        .then((response) => {
          this.packData = response.data.payload;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addToCart(item, id, item_type) {
      this.$parent.addToCart(item, id, item_type);
    },
    getImageUrl(item) {
      if (this.packData) {
        return item.image_url;
      }
      return this.$parent.imgDomain + item.img_url;
    },
    getColor(attribute) {
      return this.colorMap[attribute.toLowerCase()] || "";
    },
    createChart() {
      const canvas = this.$refs.chartCanvas;
      if (canvas && this.chartData.length > 3) {
        this.destroyChart();
        const ctx = canvas.getContext("2d");
        const min = Math.min(...this.chartData.map((data) => data.price));
        const max = Math.max(...this.chartData.map((data) => data.price));
        const stepSize = (max - min) / 4;
        const minWithStep = min - stepSize;
        const maxWithStep = max + stepSize;

        this.chart = new Chart(ctx, {
          type: "line",
          data: {
            labels: this.chartData.map((data) => data.date),
            datasets: [
              {
                label: "Price",
                data: this.chartData.map((data) => ({
                  x: data.date,
                  y: data.price,
                })),
                borderColor: "#256885",
                borderWidth: 2,
                pointBackgroundColor: "#256885",
                pointBorderColor: "#256885",
                pointRadius: 0,
                pointHoverRadius: 5,
                tension: 0,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                top: 16,
                right: 32,
                bottom: 16,
                left: 16,
              },
            },
            scales: {
              xAxes: [
                {
                  type: "time",
                  time: {
                    unit: "day",
                    displayFormats: {
                      day: "MMM D",
                    },
                    tooltipFormat: "MMM D YYYY",
                  },
                  gridLines: {
                    color: "#3B4858",
                    lineWidth: 1,
                    drawBorder: true,
                    drawOnChartArea: true,
                  },
                  ticks: {
                    autoSkip: true,
                    maxTicksLimit: 8,
                    fontFamily: "Oxanium",
                    fontSize: 14,
                    fontColor: "white",
                    // callback: (value, index) => {
                    //   return this.chartData[index].date.slice(0, -4);
                    // }
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "#3B4858",
                    lineWidth: 1,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: false,
                  },
                  ticks: {
                    max: maxWithStep,
                    min: minWithStep,
                    stepSize: stepSize,
                    autoSkip: false,
                    fontFamily: "Oxanium",
                    fontSize: 14,
                    fontColor: "white",
                    padding: 12,
                    callback: (value, index, values) => {
                      if (index === 0 || index === values.length - 1) return "";
                      return `${value.toFixed(2)} ${this.currency}`;
                    },
                  },
                  afterFit: (scaleInstance) => {
                    scaleInstance.paddingTop = 10;
                    scaleInstance.paddingBottom = 10;
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
            tooltips: {
              backgroundColor: "#383D69",
              titleFontFamily: "Oxanium",
              titleFontSize: 14,
              titleFontStyle: "medium",
              bodyFontFamily: "Oxanium",
              bodyFontStyle: "medium",
              bodyFontSize: 14,
              xPadding: 10,
              yPadding: 10,
              displayColors: false,
              callbacks: {
                title: (tooltipItems) => {
                  const dataPoint = this.chartData[tooltipItems[0].index];
                  return (
                    tooltipItems[0].label +
                    "   " +
                    (dataPoint.count || 1) +
                    " sold"
                  );
                  // return tooltipItems[0].label;
                },
                label: (tooltipItem) => {
                  return `CDN: ${tooltipItem.yLabel.toFixed(2)} ${
                    this.currency
                  }`;
                },
              },
            },
            elements: {
              point: {
                radius: 1,
                hoverRadius: 6,
                hitRadius: 20,
                backgroundColor: "#B63241",
                hoverBackgroundColor: "#B63241",
                borderWidth: 0,
                hoverBorderWidth: 10,
                hoverBorderColor: "#B6324166",
              },
            },
          },
        });
      }
    },
    destroyChart() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    },
  },
  beforeDestroy() {
    this.destroyChart();
  },
};
</script>

<style scoped lang="scss">
.product-details-container {
  display: flex;
  justify-content: space-between;
  max-width: 1220px;
  min-height: 618px;
  margin: 0 auto;
  padding: 24px 10px 95px 10px;
  gap: 10px;
}

.product-image-container {
  flex: 0 1 548px;
  // background: #20222F;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 100%;

  .added {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #b63241;
    font-weight: 700;
    text-align: left;
    font-size: 42px;
    line-height: 51px;
  }
}

.product-image {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: 100%;
  max-height: 364px;
}

.product-info-container {
  flex: 0 1 597px;
  width: 100%;
  max-width: 100%;
}

.product-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 55px;
  text-align: left;
  color: white;
  margin-bottom: 16px;
  word-wrap: break-word;
}

.product-details {
  height: 48px;
}

.product-type,
.product-quality,
.product-hero {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.88px;
  text-align: left;
  color: white;
}

.product-type {
  margin-bottom: 8px;
  font-weight: 700;
  text-transform: capitalize;
}

.product-quality {
  margin-bottom: 8px;
}

.product-hero {
  // margin-bottom: 16px;
}

.price-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0;
  height: 26px;
}

.price-container.has-discount {
  gap: 8px;
  align-items: center;
}

.old-price {
  font-family: "Quantico", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: white;
  text-decoration: line-through;
}

.price {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  text-align: left;
}

.price.discounted {
  text-align: right;
  color: #b63241;
}

.chart-section {
  margin-bottom: 20px;
  background: #1f233c;
}

.chart-title {
  padding: 16px 0 0 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.88px;
  text-align: left;
  color: white;
}

.chart-container {
  height: 300px;
  width: 100%;
  border-radius: 4px;
  position: relative;
}

.add-to-cart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  max-width: 228px;
  width: 100%;
  height: 44px;
  padding: 12px 8px;
  border-radius: 4px;
  line-height: 16px;
  margin-bottom: 26px;
  background: linear-gradient(180deg, #b63241 12.5%, #256885 85.42%);

  span {
  }

  img {
    width: 22px;
  }
}

.desc.green {
  text-align: center;
  max-width: 273px;
  width: 100%;
}

.content_desc {
  margin-top: 20px;
  color: white;
}

.disclaimer {
  color: white;
  margin-top: 20px;
  font-size: 12px;

  h2 {
    font-size: 14px;
  }

  p {
    margin-top: 10px;
  }
}

.last-sale {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  .desc {
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 1220px) {
  .product-details-container {
    flex-direction: column;
    align-items: center;
  }

  .product-image-container {
    width: 100%;
    max-width: 572px;
    height: auto;
    aspect-ratio: 548 / 364;
    flex: 0;
  }

  .product-info-container {
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
    max-width: 572px;
  }
}
</style>
