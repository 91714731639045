<template>
    <div class="pagination-container">
        <div class="result">{{ $t(`Result per page`) }}</div>
        <div class="items-per-page">
            <div class="filter-dropdown" ref="dropdownRef" @click="toggleDropdown">
                <div>
                    <div class="filter-value">{{ perPage }}</div>
                </div>
                <div class="img-wrapper">
                    <img src="@/assets/arrow-down.svg" :class="{ 'rotated': dropdownVisible }" alt="arrow" />
                </div>
                <div v-if="dropdownVisible" class="dropdown-menu" @click.stop>
                    <div v-for="option in itemsPerPageOptions" :key="option" @click="changeItemsPerPage(option)"
                        class="dropdown-item">
                        {{ option }}
                    </div>
                </div>
            </div>
        </div>

        <div class="pagination-controls">
            <button class="arrow-button" @click="prevPage" :disabled="isOnFirstPage">
                <img src="@/assets/next.svg" alt="Previous" style="transform: rotate(180deg);" />
            </button>

            <div class="page-numbers">
                <span @click="goToPage(1)" :class="{ active: currentPage === 1 }" class="page-number">1</span>
                <span v-if="startPage > 2" class="dots">...</span>
                <span v-for="page in displayedPages" :key="page" @click="goToPage(page)"
                    :class="{ active: currentPage === page }" class="page-number">
                    {{ page }}
                </span>
                <span v-if="endPage < totalPages - 1" class="dots">...</span>
                <span v-if="totalPages > 1" @click="goToPage(totalPages)"
                    :class="{ active: currentPage === totalPages }" class="page-number">
                    {{ totalPages }}
                </span>
            </div>

            <button class="arrow-button" @click="nextPage" :disabled="isOnLastPage">
                <img src="@/assets/next.svg" alt="Next" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        totalPages: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            default: 16
        },
        currentPage: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            dropdownVisible: false,
            itemsPerPageOptions: [16, 32, 48],
        };
    },
    computed: {
        startPage() {
            if (this.currentPage <= 3) return 2;
            if (this.currentPage >= this.totalPages - 2) return this.totalPages - 3;
            return this.currentPage - 1;
        },
        endPage() {
            if (this.currentPage <= 3) return Math.min(5, this.totalPages - 1);
            if (this.currentPage >= this.totalPages - 2) return this.totalPages - 1;
            return this.currentPage + 1;
        },
        displayedPages() {
            const pages = [];
            for (let i = this.startPage; i <= this.endPage; i++) {
                if (i !== 1 && i !== this.totalPages) {
                    pages.push(i);
                }
            }
            return pages;
        },
        isOnFirstPage() {
            return this.currentPage === 1;
        },
        isOnLastPage() {
            return this.currentPage === this.totalPages;
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },
        changeItemsPerPage(newPerPage) {
            this.$emit('update:perPage', newPerPage);
            this.$emit('perPageChange', newPerPage);
            this.dropdownVisible = false;
        },
        goToPage(page) {
            if (page !== this.currentPage) {
                this.$emit('update:currentPage', page);
            }
        },
        prevPage() {
            if (!this.isOnFirstPage) {
                this.goToPage(this.currentPage - 1);
            }
        },
        nextPage() {
            if (!this.isOnLastPage) {
                this.goToPage(this.currentPage + 1);
            }
        },
        handleClickOutside(event) {
            if (this.$refs.dropdownRef && !this.$refs.dropdownRef.contains(event.target)) {
                this.dropdownVisible = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    margin: 24px 0 20px auto;
    flex-wrap: wrap;
    // border-radius: 12px;
    // background: #384047;
}

.result {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: white;
    margin-right: 7px;
}

.items-per-page {
    margin-right: 16px;
}

.filter-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 0px 8px 0px 8px;
    border-radius: 4px;
    border: 1px solid #fff;
    // background: #20222F;
    cursor: pointer;
    position: relative;
    max-width: 63px;
    width: 100%;
    height: 24px;


    .img-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }

    img {
        width: 12px;
        height: 6px;
    }
}

.filter-label {}

.filter-value {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: #fff;
}

.dropdown-menu {
    position: absolute;
    top: 36px;
    left: 0;
    background-color: #383D69;
    color: white;
    border: 1px solid #fff;
    border-radius: 4px;
    width: 100%;
    z-index: 10;
}

.dropdown-item {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: #fff;
    padding: 6px 8px;
    border-radius: 8px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #2a2d3a;
}

.pagination-controls {
    display: flex;
    align-items: center;
}

.arrow-button {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #B63241 12.5%, #256885 85.42%);
    opacity: 1;

    img {
        width: 6px;
        height: 12px;
    }
}

.arrow-button:disabled {
    opacity: 0.5;
    background: linear-gradient(180deg, rgba(182, 50, 65, 0.4) 12.5%, rgba(37, 104, 133, 0.4) 85.42%);
    cursor: not-allowed;
}

.page-numbers {
    display: flex;
    align-items: center;
    margin: 0 12px;
}

.page-number {
    min-width: 24px;
    width: auto; height: 24px;

    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: white;
}

.page-number.active {
    background: linear-gradient(180deg, #B63241 12.5%, #256885 85.42%);
    border-radius: 4px;
}

.dots {
    color: white;
    cursor: default;
    margin: 0 2px;
}

@media (max-width: 460px) {
    .pagination-container {
        gap: 10px;
        height: auto;
    }

    .items-per-page {
        margin-right: 0;
    }
}
</style>